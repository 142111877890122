
.wrap {
    /*color: cyan;*/
    /*background-color: cyan;*/
    width: 100%;
    transition: all 1s ease;
    display: flex;
    /*height: 40px;*/
    justify-content: center;
    position: fixed;
    z-index: 10;
    height: 45px;
    /*backdrop-filter: blur(10px);*/
}

/*@media (max-width: 460px) {*/
/*    .wrap {*/
/*        width: unset;*/
/*    }*/
/*}*/

.container {
    width: 80%;
    /*max-width: 960px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mySticky {
    /*backdrop-filter: blur(10px);*/
    background-color: white;
    top: 0px !important;
}

.burgerMenu {
    /*height: 40px;*/
    cursor: pointer;
}

.name {
    font-family: 'Lora';
    font-style: italic;
    font-weight: bold;
    font-size: 32px;
}



.display {
    display: flex;
    align-items: center;
    gap: 16px;
}

@media (max-width: 460px) {
    .container {
        width: 90%;
    }
    .name {
        font-size: 18px;
    }
    .display {
        gap: 8px
    }
}
.wrap {
    /*color: black;*/
    /*height: 100vh;*/
    width: 100%;
    display: flex;
    justify-content: center;
    /*background-color: #ffffff;*/
    /*background-image: url('https://kartinkin.net/pics/uploads/posts/2022-08/1660628383_1-kartinkin-net-p-fon-muzikalnii-nezhnii-krasivo-1.jpg');*/
    /*background-size: contain;*/
    /*background-repeat: round;*/
    /*position: relative;*/

}

.container {
    /*font-size: 24px;*/
    font-weight: 400;
    width: 80%;
    /*margin: 0 auto;*/
    /*padding-top: 32px;*/
    /*position: absolute;*/
    /*top:50%;*/
    /*left:50%;*/
    /*transform:translate(-50%, -50%);*/
    /*z-index: 4;*/
    color: #212529;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*font-size: calc(1.425rem + 2.1vw) !important;*/
    /*background-image: url('https://phonoteka.org/uploads/posts/2021-05/1620110622_49-phonoteka_org-p-zolotoi-muzikalnii-fon-59.jpg')*/
    padding: 48px 0px 32px 0px;
}



.display {
    display: flex;
    align-items: center;
}

.generalTitle {
    padding: 0px 4px;
    background-color: #ffd4c1;
    line-height: 1.8;
}

.detailTitle {
    font-weight: 600;
    font-size: 2.5rem;
    margin-top: 6px;
    margin-bottom: 2rem;
    line-height: 1.2;
}

.wrapWelcome {
    align-self: baseline;
    white-space: break-spaces;
    margin-bottom: 1rem;
}

.welcome {
    font-size: 32px;
}

.welcomeFio {
    border-bottom: 3px solid #ffd4c1;
    font-size: 32px;
    font-weight: bold;
}

.wrapText {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.aboutMeText {
    /*position: absolute;*/
    display: flex;
    width: 75%;
    align-self: baseline;

}

.wrapNumber {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.number {
    display: flex;
    font-size: 9rem;
    border-radius: 50%;
    background-color: #ffd4c1;
    height: 120px;
    width: 120px;
    min-width: 120px;
    align-items: center;
    /*font-weight: bold;*/
    justify-content: center;
}

.experienceText {
    display: flex;
    margin-top: 24px;
    justify-content: center;
    align-items: center;
    font-size: 1.75rem;
    white-space: pre;
}

.wrapText{
    display: flex;
    line-height: 1.8;
    flex-direction: column;
}

.wrapPersonalData {
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    line-height: 1.8;
}

.personalDataBlock {
    display: flex;
    flex-direction: column;
    /*gap: 12px*/
}

.personalDataTitle {
    color: #8e9a9d;
    font-weight: 500;
}

.personalDataDescription {
    font-weight: 600;
}

.personalDataDescription a {
    color: initial;
}

/*.wrapGrid {*/
/*    display: grid;*/
/*    gridTemplateColumns: repeat(auto-fit, minmax(596px, 3fr));*/
/*    gap: 16px;*/
/*    height: auto;*/
/*    width: calc(100% + 3px);*/
/*    !*color: `${store.appStore.theme.textPrimary}`,*!*/
/*}*/

.wrapImg {
    /*position: relative;*/
    width: 100%;
    height: 100%;
    /*background: linear-gradient( rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0.5) );*/
    z-index: 3;
}

.img {
    /*background: rgba(0,0,0,.5);*/
    /*opacity:0.3;*/
    /*filter: blur();*/
    /*background-color:#000;*/
    /*position: absolute;*/
    width: 100%;
    height: 100vh;
    /*background: linear-gradient( rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0.5) );*/
    z-index: 2;
}

.wrapImg::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0.5));
    /*background: rgba(0,0,0,.5);*/
    z-index: 2;
}

@media (max-width: 460px) {
    .container {
        width: 90%;
    }
    .detailTitle {
        font-size: 2rem;
        text-align: center;
    }
}